/* MapBox overrides */
.mapboxgl-control-container {
  display: none;
}

div.mapboxgl-ctrl-geocoder {
  width: unset;
  max-width: unset;
  box-shadow: unset;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

div.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
  position: relative;
  top: unset;
  left: unset;
  width: unset;
  height: unset;
}

input.mapboxgl-ctrl-geocoder--input {
  border: none;
  max-height: 36px;
  padding: 0.5rem !important;
}

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl:not(:first-of-type) {
  display: none;
}

#default_location .mapboxgl-ctrl-geocoder--input {
  min-height: 40px;
}

svg.markerIcon {
  height: 100%;
  width: 100%;
}

a.active-nav::after {
  content: '';
  position: absolute;
  bottom: 0.75rem;
  background-color: #7b7575;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}
